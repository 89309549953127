import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { ReactComponent as SquareSVG } from '../components/assets/header-icons/square.svg';

import { ReactComponent as loveSVG } from '../components/assets/listing/love_web.svg';
import { ReactComponent as shareSVG } from '../components/assets/listing/share_web.svg';
import Contact from '../components/main/Contact';
import ItemIcon from '../components/main/ItemIcon';

// import {
//   AiOutlineHeart,
//   AiFillHeart,
//   // AiFillStar,
//   // AiOutlinePlus,
//   // AiOutlineMessage,
// } from 'react-icons/ai';
// import { BsFillShareFill } from 'react-icons/bs';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReadMore from '../components/listing/ReadMore';
// import GetInTouchBtn from '../components/listing/GetInTouchBtn';
import ListingRecommendedSide from '../components/listing/ListingRecommendedSide';
import Share from '../components/main/Share';
import { Dropdown } from 'flowbite-react';
import { propertyTypesInitialState, featuresList } from '../data/data';
import { Property, ParamsPerType, PostPayload } from '../types/types';
import ListingMap from '../components/listing/ListingMap';
import Gallery from '../components/listing/Gallery';
import Loader from '../components/main/Loader';
import SEO from '../components/SEO/SEO';
import { useProperty } from '../contexts/property';
import { useGlobal } from '../contexts/global';
import { useAuth } from '../contexts/auth';
import { useInternationalization } from '../contexts/internationalization';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  increaseViewCount,
  toggleSaveProperty,
  isPropertySaved,
} from '../services/propertyService';
import { CONDO, LAND } from '../constants/constant';
import MobileSlideshow from '../components/listing/MobileSlideshow';
import { useScroll } from '../contexts/scroll';
import Tooltip from '../components/form/Tooltip';
import { adjustImageSize } from '../utils/adjustImageSize';
import { useChat } from '../contexts/chat';
import { createAdsPostService } from '../services/postService';
import { format } from 'date-fns';
import { getFurnishingStatusLabel } from '../utils/adaptStatusStyle';
import { useTranslation } from 'react-i18next';
import TranslateTextDropdown from '../components/main/TranslateTextDropdown';
import { getCountryAreaUnit } from '../components/dashboard/tabsContents/ListingBasicContent';

export const formatLocation = (data: Property) => {
  // take the first string if there is a comma (google address)
  let formatCity = data?.city.includes(',') ? data?.city.split(',')[0] : data?.city;
  // ensure the first letter is capital letter
  formatCity = formatCity.charAt(0).toUpperCase() + formatCity.slice(1);

  let formatCountry = data?.country.charAt(0).toUpperCase() + data?.country.slice(1);

  let formatStreetName =
    data?.streetNameHouseNumber.charAt(0).toUpperCase() + data?.streetNameHouseNumber.slice(1);

  data.country = formatCountry;
  data.city = formatCity;
  data.streetNameHouseNumber = formatStreetName;

  console.log('format location', data);

  return data;
};

export const getSVGByTitle = (
  titleToFind: string
): React.FC<React.SVGProps<SVGSVGElement>> | undefined => {
  const foundFeature = featuresList.find((feature) => feature.title === titleToFind);
  return foundFeature ? foundFeature.SVG : undefined;
};

export const Avatar = ({ isSales, image, size = 16 }: any) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex w-20 mx-auto">
      {isSales && (
        <div className="group absolute top-1 left-0">
          <Tooltip
            text={t('Deehiy Ambassador')}
            Component={() => <img className=" w-6 h-6 " src="/images/icons/sales-rep.svg" />}
          />
        </div>
      )}
      <img
        src={adjustImageSize(image)}
        alt=""
        className={`w-${size} h-${size} mt-1 mb-2 rounded-full mx-auto`}
      />
    </div>
  );
};

const checkPropertyAccess = (property: Property, navigate: any) => {
  // Check if the property is published and status is 'approved'
  if (property.published && property.status === 'approved') {
    return true;
  }

  // If none of the above conditions are met, navigate to '/unpublished'
  navigate('/unpublished');
  return false;
};

const ListingPage = () => {
  const { id } = useParams();
  const { fetchPublicPropertyById } = useProperty();
  const { currentUser } = useAuth();
  const { currency } = useInternationalization();
  const { setVisible, setIsActive } = useScroll();
  const { isWL } = useGlobal();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPosted, setIsPosted] = useState(false);
  const [property, setProperty] = useState<any>(null);
  const [contact, setContact] = useState<any>(null);
  const [isSaved, setIsSaved] = useState(false);
  const [params, setParams] = useState<ParamsPerType | null>(null);
  const { chatHandler } = useChat();
  const [translatedText, setTranslatedText] = useState('');

  const [areaUnit, setAreaUnit] = useState({
    homeUnitTitle: 'square meter',
    homeUnit: 'sq.m',
    landUnitTitle: 'square meter',
    landUnit: 'sq.m',
  });
  const [promoted, setPromoted] = useState({
    link: `${process.env.REACT_APP_BASE_URL_PROD}/square`,
    banner:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/f_auto,q_auto/v1711953016/banner-Square_e7w12x.png',
  });

  // const [isDisabled, setIsDisabled] = useState(false);
  // const [isSendingRequest, setIsSendingRequest] = useState(false);

  // const [isContacted, setIsContacted] = useState(false);

  const saveProperty = () => {
    if (!currentUser?.id) {
      toast.info('Please, log in to save this property');
      return;
    }
    toggleSaveProperty(Number(id), currentUser.id).then((res) => {
      if (res.data === 'saved') {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    });
  };

  const updateViewCount = () => {
    if (id) {
      try {
        increaseViewCount(Number(id));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const handleContact = async () => {
  //   if (!currentUser?.id) {
  //     toast.info('Please, log in to send a request to the agent');
  //     return;
  //   }
  //   setIsSendingRequest(true);
  //   const userId = currentUser?.id;
  //   const contactId = contact.isBusiness ? contact.adminId : contact?.id;
  //   const propertyId = Number(id);

  //   try {
  //     if (userId && contactId && propertyId)
  //       await sendContactRequestToAdmin(userId, contactId, propertyId);
  //     setIsDisabled(true);
  //     toast.success('contact request sent!');
  //   } catch (err: any) {
  //     toast.error('oops, something went wrong');
  //     throw new Error(err);
  //   } finally {
  //     setIsSendingRequest(false);
  //   }
  // };

  const contactId = contact?.isBusiness ? contact?.userId : contact?.id;

  const chatButtonHandler = async () => {
    var nameParts = contact?.name.split(' ');
    var firstName = nameParts[0];
    if (currentUser?.id) {
      await chatHandler(contactId, firstName || '');
    } else {
      navigate('/login', { state: { redirectTo: location } });
    }
  };

  // const addContactHandler = async () => {
  //   if (currentUser?.id) {
  //     await addToContact(contact?.id);
  //   }
  // };

  const handlePost = async () => {
    if (!currentUser?.id) {
      navigate('/login', { state: { redirectTo: location } });
      return;
    }

    try {
      const postPayload: PostPayload = {
        authorId: currentUser?.id,
        interest: '',
        content: '',
        media: [{ url: property?.images[0].url, type: 'IMG' }],
        title: '',
        type: 'general',
        aboutPropertyId: property.propertyId,
      };

      if (contact.isBusiness) {
        postPayload.aboutBusinessId = contact.id;
      } else {
        postPayload.aboutMemberId = contact?.id;
      }

      await createAdsPostService(postPayload);
      setIsPosted(true);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (!id) navigate('/');

    setIsLoading(true);
    fetchPublicPropertyById(Number(id), currency)
      .then((data: any) => {
        checkPropertyAccess(data.property, navigate);

        console.log('DATA::', data);
        const formattedProperty = formatLocation(data.property);

        setProperty(formattedProperty);

        setContact(formattedProperty.contact);
        setPromoted({ link: data.promotedCard.link, banner: data.promotedCard.banner });
        setAreaUnit(getCountryAreaUnit(formattedProperty.isoCodeCountry));
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
    updateViewCount();
  }, []);

  useEffect(() => {
    setIsActive(false);
    setVisible(false);

    return () => {
      setIsActive(true);
      setVisible(true);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchPublicPropertyById(Number(id), currency)
      .then((data) => {
        const formattedProperty = formatLocation(data.property);

        setProperty(formattedProperty);
        setContact(formattedProperty.contact);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [currency]);

  useEffect(() => {
    if (property?.propertyDescription) {
      setTranslatedText(property.propertyDescription);
    }
    // check if the property has been saved by the current user before
    if (property && currentUser?.id) {
      isPropertySaved(property.propertyId, currentUser.id)
        .then((response) => {
          console.log(response.data.message);
          setIsSaved(true);
        })
        .catch(function (error) {
          if (error.response.status === 404) {
            setIsSaved(false);
          } else {
            console.log('Server error');
          }
        });
    }
  }, [property]);

  useEffect(() => {
    const selectedType = propertyTypesInitialState.find(
      (el: ParamsPerType) => el.propertyType === property?.propertyType
    );
    if (selectedType) setParams(selectedType);
  }, [property?.propertyType]);

  if (isLoading || !property)
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="xl:max-w-[1430px] lg:max-w-[1240px] container  mx-auto px-0 sm:px-0 md:px-4 pb-8">
      {property && (
        <SEO
          title={property?.propertyTitle}
          description={property?.propertyDescription}
          name="deehiy"
          type="listing page"
          image={property?.images[0].url}
        />
      )}

      {/* slideshow for Mobile */}
      <div className="block sm:hidden">
        <MobileSlideshow
          isWL={isWL}
          businessId={property.businessId}
          list={property?.images}
          propertyId={property?.propertyId}
          isSaved={isSaved}
          saveProperty={saveProperty}
        />
      </div>
      {/* title and share buttons */}

      <div className="flex justify-between pt-2 sm:pt-10 items-bottom px-4 sm:px-0">
        <div className="listing-title w-full sm:w-3/5">
          <div className="line-clamp-2 sm:line-clamp-1">{property?.propertyTitle}</div>
        </div>
        {!isWL && (
          <div className="hidden sm:flex items-center justify-end font-bold space-x-6 sm:space-x-12 duration-200 w-2/5">
            <Dropdown
              arrowIcon={false}
              inline={true}
              placement="bottom-end"
              label={
                <div className=" hover:text-deehiy text-defaultText  justify-center">
                  {/* <BsFillShareFill className="my-auto w-4 h-4 sm:w-4 sm:h-4" /> */}
                  <ItemIcon size={5} Icon={shareSVG} />
                </div>
              }
            >
              <Dropdown.Item>
                <Share
                  type={`${isWL ? 'wl-properties' : 'properties'}`}
                  id={property?.propertyId}
                />
              </Dropdown.Item>
            </Dropdown>

            {isSaved ? (
              <button
                onClick={saveProperty}
                className="flex text-base text-red-400 justify-center items-center"
              >
                {' '}
                <ItemIcon size={5} Icon={loveSVG} />
                {/* <AiFillHeart className="text-red-400 w-4 h-4 mb-1 sm:mb-0 sm:w-5 sm:h-5" /> */}
              </button>
            ) : (
              <button
                onClick={saveProperty}
                className=" hover:text-deehiy text-defaultText justify-center items-center"
              >
                <>
                  {/* <AiOutlineHeart className="my-auto w-4 h-4 sm:w-5 sm:h-5" /> */}
                  <ItemIcon size={5} Icon={loveSVG} />
                </>
              </button>
            )}
            {isPosted ? (
              <div className=" flex px-2 py-1 rounded-xl border border-defaultText">Posted!</div>
            ) : (
              <div
                onClick={handlePost}
                className=" gap-x-2 flex px-2 py-1 rounded-xl border border-defaultText items-center hover:cursor-pointer md:text-xs lg:text-base "
              >
                {t('Post on Square')}
                <ItemIcon Icon={SquareSVG} size={5} />{' '}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="px-4 mt-[-14px] flex sm:hidden listing-details-section">
        {property?.city}, {property?.country}
      </div>
      {/* images gallery */}
      <div className="hidden sm:block px-4 sm:px-0">
        <Gallery images={property?.images} />
      </div>
      {/* body */}
      <span className="hidden sm:block text-gray-400 px-4 sm:px-0 text-xs sm:text-sm">
        {t('listing_ID')}: {property?.propertyId}
      </span>
      <div className="flex flex-col w-full sm:flex-row text-sm sm:text-sm px-4 sm:px-0">
        {/* details Desktop */}
        <div className="flex flex-col w-full sm:w-3/4 pt-6 sm:pt-12 gap-x-2 sm:gap-x-8">
          <div className=" flex-col space-y-3 sm:space-y-0 sm:grid grid-flow-col">
            {property && property?.propertyType !== LAND && (
              <>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Bedrooms')}</span>
                  {/* <span className="font-semibold"> {property?.bedrooms}</span> */}
                  <span className="font-semibold">
                    {' '}
                    {property?.bedrooms === 0
                      ? 'Studio'
                      : property?.bedrooms >= 9
                      ? '9+'
                      : property?.bedrooms}
                  </span>
                </div>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Baths')}</span>
                  {/* <span className="font-semibold"> {property?.bathrooms}</span> */}
                  <span className="font-semibold">
                    {' '}
                    {property?.bathrooms >= 9 ? '9+' : property?.bathrooms}
                  </span>
                </div>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Home size')}</span>
                  <span className=" font-semibold">
                    {' '}
                    {property?.homeSize} {t(areaUnit.homeUnit)}
                  </span>
                </div>
              </>
            )}
            {property && property?.propertyType !== CONDO && (
              <div className="flex justify-between sm:flex-col sm:space-y-3">
                <span>{t('Lot size')}</span>
                <span className="font-semibold">
                  {' '}
                  {property?.lotSize} {t(areaUnit.landUnit)}
                </span>
              </div>
            )}
          </div>
          <div className="listing-details-section whitespace-pre-wrap">
            <TranslateTextDropdown text={translatedText} setTranslatedText={setTranslatedText} />
            <ReadMore max={400}>{translatedText}</ReadMore>
          </div>

          <section className="flex-col listing-details-section">
            <span className="listing-title">{t('Property information')}</span>
            <div className="flex py-6 sm:py-8">
              <div className="flex flex-col sm:flex-row w-full gap-x-3 sm:gap-x-24 gap-y-4">
                <div className="flex flex-col w-full sm:w-1/2 space-y-4">
                  <div className="flex justify-between">
                    <span>{t('Country')}</span>
                    <span className="text-md font-semibold flex justify-end">
                      {property?.country}
                    </span>
                  </div>
                  {params?.details.builtIn && property?.builtInDate && (
                    <div className="flex justify-between">
                      <span>{t('Built in')}</span>
                      <span className="font-semibold flex justify-end whitespace-nowrap">
                        {property?.builtInDate}
                      </span>
                    </div>
                  )}

                  {params?.details.floorLevel &&
                    property?.floorLevel != null &&
                    property.floorLevel !== 0 && (
                      <div className="flex justify-between">
                        <span>{t('Floor level')}</span>
                        <span className="font-semibold flex justify-end">
                          {property?.floorLevel}
                        </span>
                      </div>
                    )}
                  {property?.isForRent && (
                    <div className="flex justify-between">
                      <span>{t('Available from')}</span>
                      <span className="font-semibold flex justify-end">
                        {property?.availability
                          ? format(new Date(property.availability), 'dd/MM/yyyy')
                          : t('now')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full sm:w-1/2 justify-end space-y-4">
                  <div className="flex w-full gap-x-6 justify-between">
                    <span>{t('City')}</span>
                    <span className="font-semibold flex justify-end line-clamp-1">
                      {property?.city}
                    </span>
                  </div>
                  {params?.details.lastRenovation && property?.lastRenovationDate && (
                    <div className="flex justify-between">
                      <span className="whitespace-nowrap">{t('Last renovation in')}</span>
                      <span className="font-semibold flex justify-end whitespace-nowrap">
                        {property?.lastRenovationDate}
                      </span>
                    </div>
                  )}
                  {params?.details.ceilingHeight &&
                    property?.ceilingHeight != null &&
                    property.ceilingHeight !== 0 && (
                      <div className="flex justify-between">
                        <span className="whitespace-nowrap">{t('Ceiling height')}</span>
                        <span className="font-semibold flex justify-end">
                          {property.ceilingHeight} m
                        </span>
                      </div>
                    )}
                  {params?.details.parking &&
                    property?.parkingLots != null &&
                    property.parkingLots !== 0 && (
                      <div className="flex justify-between">
                        <span className="whitespace-nowrap">{t('Parking lots')}</span>
                        <span className="font-semibold flex justify-end whitespace-nowrap">
                          {property?.parkingLots}
                        </span>
                      </div>
                    )}
                  {params?.details.furnishingStatus && property?.furnishingStatus && (
                    <div className="flex justify-between">
                      <span>{t('Furnishing status')}</span>
                      <span className="font-semibold flex justify-end">
                        {t(getFurnishingStatusLabel(property?.furnishingStatus))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="hidden w-full gap-8 place-content-center">
              <div
                className="flex flex-col text-black w-full cursor-pointer"
                onClick={() => window.open(promoted.link, '_blank')}
              >
                <img alt="" src={promoted.banner} className="w-full" />
              </div>
            </div>
            <div className="flex w-full gap-8 place-content-center">
              <div
                className="flex flex-col text-black w-full cursor-pointer"
                onClick={() => navigate('/users/addhunt', { state: { redirectTo: location } })}
              >
                <img alt="" src="/images/banner_hunt.png" className="w-full" />
              </div>
            </div>
          </section>

          <section className="flex flex-col listing-details-section">
            <div className="listing-title">{t('What this property offers')}</div>

            <ul className=" overflow-auto grid grid-cols-2 max-h-[250px]">
              {property?.features.map((f: any) => {
                return (
                  <li className="flex gap-x-3 items-center text-deehiy">
                    <ItemIcon Icon={getSVGByTitle(f.featureTitle)} size={10} />
                    <span className="text-defaultText">{t(f.featureTitle)}</span>
                  </li>
                );
              })}
            </ul>
          </section>

          {/* location map */}
          <section className="flex flex-col listing-details-section">
            <div className="listing-title">{t('This property is here')}</div>

            {property.showExactLocation && (
              <div className="flex whitespace-pre-wrap gap-x-1 pb-4">
                <span>{t('Address')}:</span>
                <span className="semibold">
                  {`${property?.streetNameHouseNumber}, ${property?.postalCode}, ${property?.city}, ${property?.country}`}
                </span>
              </div>
            )}

            <ListingMap
              center={{ lat: property?.latitude, lng: property?.longitude }}
              isToggled={property.showExactLocation}
            />
          </section>
        </div>
        {/* price and recommended properties */}
        <div className="flex flex-col w-full sm:w-1/3  pl-0 sm:pl-20 pt-0 pb-12 sm:pt-12 sm:pb-6">
          {/* price */}
          <div className="sticky top-20 flex w-full justify-evenly flex-row sm:flex-col space-y-2 border-none sm:border border-deehiy rounded-2xl shadow-none sm:shadow-xl p-4 sm:p-10 mx-auto">
            {property.isForSale || property.isOffPlan ? (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">{t('Sales price')}</span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <>
                    {property.isPOR ? (
                      <div className="text-deehiy font bold">{t('Price on Request')}</div>
                    ) : (
                      <NumericFormat
                        value={property?.listingPrice}
                        thousandSeparator=" "
                        displayType={'text'}
                        prefix={currency + ' '}
                      />
                    )}
                  </>
                </span>
                {property.hasSellerFinance && (
                  <div className="flex gap-x-2 font-normal items-center text-sm">
                    <img src="/images/icons/seller-finance.svg" className="w-5 h-5" />
                    <span>{t('Seller finance available')}</span>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {property.isForRent && (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">
                  {t('Rental price / month')}
                </span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <NumericFormat
                    value={property?.monthlyRentPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={currency + ' '}
                  />
                </span>
              </div>
            )}
            {property.hasDailyRental && (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">
                  {t('Rental price / day')}
                </span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <NumericFormat
                    value={property?.dailyRentPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={currency + ' '}
                  />
                </span>
              </div>
            )}
            <div className="flex sm:hidden">
              <Avatar isSales={contact?.isSalesRep} image={contact?.image} />
            </div>

            <hr className="pb-4 opacity-80 inline-block border-deehiy" />
            <div className="flex flex-row sm:flex-col">
              <span className="hidden sm:flex text-sm sm:text-base">{t('Listed by')}</span>
              <div className="flex space-x-6 justify-center items-center">
                <Link to={contact.isBusiness ? `/business/${contact.id}` : `/member/${contact.id}`}>
                  <div className="hidden sm:flex">
                    <Avatar isSales={contact?.isSalesRep} image={contact?.image} size={20} />
                  </div>
                  <span className="font-semibold">
                    <span className="flex items-baseline gap-2">
                      <span className="flex sm:hidden text-sm sm:text-base font-normal">
                        {t('Listed by')}
                      </span>
                      <span className="mx-auto">{contact.name}</span>
                      {contact?.isVerified && (
                        <Tooltip
                          text={t('this user has provided proof of identity')}
                          Component={() => (
                            <img
                              src="/images/icons/verified_profile.svg"
                              className="text-deehiy w-5 h-5"
                            />
                          )}
                        />
                      )}
                    </span>
                    {/* <div className="flex py-2 items-center text-deehiy justify-center">
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <span>5</span>
                      <span className="whitespace-nowrap flex pl-3 text-sm items-baseline font-light text-gray-400">
                        (12 reviews)
                      </span>
                    </div> */}
                  </span>
                </Link>
              </div>
            </div>
            {currentUser?.id !== contactId && (
              <div className="hidden sm:flex justify-evenly items-center pt-8 ">
                <Contact chatButtonHandler={chatButtonHandler} contact={contact} />
              </div>
            )}
          </div>
          {/* recommended properties */}
          <div className="hidden">
            <ListingRecommendedSide />
          </div>
        </div>
      </div>
      <div className="fixed sm:hidden py-3 px-4 w-full flex items-center bg-white z-10 bottom-0 justify-between border border-transparent border-t-deehiy">
        <div className="flex-flex-col">
          {property.isForSale || property.isOffPlan ? (
            <div className="flex gap-x-2 justify-between">
              <span className="flex text-sm sm:text-base">{t('Sales price')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                {property.isPOR ? (
                  <div className="text-deehiy font bold">{t('Price on Request')}</div>
                ) : (
                  <NumericFormat
                    value={property?.listingPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={currency + ' '}
                  />
                )}
              </span>
            </div>
          ) : (
            ''
          )}
          {property.isForRent && (
            <div className="flex gap-x-2 justify-between">
              <span className="flex text-sm sm:text-base">{t('Rental price')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                <NumericFormat
                  value={property?.monthlyRentPrice}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={currency + ' '}
                />
              </span>
            </div>
          )}
          {property.hasDailyRental && (
            <div className="flex text-gray-400 gap-x-2 justify-between">
              <span className="flex text-sm ">{t('Rental price / day')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                <NumericFormat
                  value={property?.dailyRentPrice}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={currency + ' '}
                />
              </span>
            </div>
          )}
        </div>
        {/* <button
          onClick={handleContact}
          className="flex py-1 px-3 sm:text-base font-bold rounded-xl  bg-deehiy text-white border gap-x-2 items-center shadow-sm"
        >
          Get in touch
        </button> */}
        {currentUser?.id !== contactId && (
          <div className="justify-center">
            <Contact chatButtonHandler={chatButtonHandler} contact={contact} />
          </div>
        )}
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default ListingPage;
