import React, { useState } from 'react';
import DOMPurify from 'dompurify';

interface ReadMoreProps {
  children: string;
  max: number;
}

const ReadMore = ({ children, max }: ReadMoreProps) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  }; // Sanitize the full HTML content

  const truncateAtWord = (inputString: any, maxLength: any) => {
    if (inputString.length <= maxLength) return inputString;
    const truncated = inputString.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
  };
  const sanitizedHTML = DOMPurify.sanitize(text);

  // Sanitize and truncate the HTML for "read more"
  const truncatedHTML = DOMPurify.sanitize(truncateAtWord(text, max));

  return (
    <p className="w-full">
      <span
        dangerouslySetInnerHTML={{
          __html: isReadMore ? truncatedHTML : sanitizedHTML,
        }}
        className="inline"
      />
      {text.length > max && (
        <span onClick={toggleReadMore} className="text-deehiy whitespace-nowrap cursor-pointer">
          {isReadMore ? ' read more' : ' show less'}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
