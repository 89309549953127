import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { ReactComponent as SquareSVG } from '../components/assets/header-icons/square.svg';

import { ReactComponent as loveSVG } from '../components/assets/listing/love_web.svg';
import { ReactComponent as shareSVG } from '../components/assets/listing/share_web.svg';

import ItemIcon from '../components/main/ItemIcon';
// import {
//   AiOutlineHeart,
//   AiFillHeart,
//   // AiFillStar,
//   // AiOutlinePlus,
//   // AiOutlineMessage,
// } from 'react-icons/ai';
// import { BsFillShareFill } from 'react-icons/bs';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReadMore from '../components/listing/ReadMore';
// import GetInTouchBtn from '../components/listing/GetInTouchBtn';
import ListingRecommendedSide from '../components/listing/ListingRecommendedSide';
import Share from '../components/main/Share';
import { Dropdown } from 'flowbite-react';
import { propertyTypesInitialState } from '../data/data';
import { ParamsPerType } from '../types/types';
import ListingMap from '../components/listing/ListingMap';
import Gallery from '../components/listing/Gallery';
import Loader from '../components/main/Loader';
import SEO from '../components/SEO/SEO';
import { useProperty } from '../contexts/property';
import { useAuth } from '../contexts/auth';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { isPropertySaved } from '../services/propertyService';
import { CONDO, LAND } from '../constants/constant';
import MobileSlideshow from '../components/listing/MobileSlideshow';
import { useScroll } from '../contexts/scroll';
import Tooltip from '../components/form/Tooltip';
import { format } from 'date-fns';
import { getFurnishingStatusLabel } from '../utils/adaptStatusStyle';
import { useTranslation } from 'react-i18next';
import TranslateTextDropdown from '../components/main/TranslateTextDropdown';
import { getCountryAreaUnit } from '../components/dashboard/tabsContents/ListingBasicContent';
import { formatLocation, Avatar, getSVGByTitle } from './ListingPage';
import Contact from '../components/main/Contact';

const PreviewListingPage = () => {
  const { id } = useParams();
  const { fetchPropertyById } = useProperty();
  const { currentUser } = useAuth();
  const { setVisible, setIsActive } = useScroll();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [property, setProperty] = useState<any>(null);
  const [contact, setContact] = useState<any>(null);
  const [isSaved, setIsSaved] = useState(false);
  const [params, setParams] = useState<ParamsPerType | null>(null);
  const [translatedText, setTranslatedText] = useState('');
  const [areaUnit, setAreaUnit] = useState({
    homeUnitTitle: 'square meter',
    homeUnit: 'sq.m',
    landUnitTitle: 'square meter',
    landUnit: 'sq.m',
    homeUnitDescription: 'Home size in square meter',
    landUnitDescription: 'Lot size in square meter',
  });
  const promoted = {
    link: `${process.env.REACT_APP_BASE_URL_PROD}/square`,
    banner:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/f_auto,q_auto/v1711953016/banner-Square_e7w12x.png',
  };

  // const [isDisabled, setIsDisabled] = useState(false);
  // const [isSendingRequest, setIsSendingRequest] = useState(false);

  // const [isContacted, setIsContacted] = useState(false);

  const saveProperty = () => {
    toast.info('Saving listing is not allowed in Preview mode');
    return;
  };

  const contactId = contact?.isBusiness ? contact?.userId : contact?.id;

  const chatButtonHandler = async () => {
    toast.info('Chatting is not allowed in Preview mode');
  };

  // const addContactHandler = async () => {
  //   toast.info('Adding contact is not allowed in Preview mode');
  // };

  const handlePost = async () => {
    toast.info('Listings can not be posted in Preview mode');
  };

  useEffect(() => {
    if (!currentUser) return;

    if (!id) navigate('/');

    setIsLoading(true);
    fetchPropertyById(Number(id))
      .then((data: any) => {
        console.log('fetched data:', data);
        const listing = formatLocation(data);

        console.log('images', listing.images);

        if (
          (listing.businessId && currentUser?.businessId === listing.businessId) ||
          (!listing.businessId && currentUser?.id === listing.userId) ||
          currentUser?.roles === 'admin'
        ) {
          console.log('-allowed');
          // const imagesStrArray = listing.images?.map((img: any) => (img.url ? img.url : img));
          // setProperty({ ...listing, images: imagesStrArray });

          setProperty(listing);

          setContact(listing.contact);
          setAreaUnit(getCountryAreaUnit(listing.isoCodeCountry));
        } else {
          console.log('--not allowed');
          alert('You are not allowed to preview this property');
          navigate(-1);
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, currentUser]);

  useEffect(() => {
    setIsActive(false);
    setVisible(false);

    return () => {
      setIsActive(true);
      setVisible(true);
    };
  }, []);

  useEffect(() => {
    if (property?.propertyDescription) {
      setTranslatedText(property.propertyDescription);
    }
    // check if the property has been saved by the current user before
    if (property && currentUser?.id) {
      isPropertySaved(property.propertyId, currentUser.id)
        .then((response) => {
          console.log(response.data.message);
          setIsSaved(true);
        })
        .catch(function (error) {
          if (error.response.status === 404) {
            setIsSaved(false);
          } else {
            console.log('Server error');
          }
        });
    }
  }, [property]);

  useEffect(() => {
    const selectedType = propertyTypesInitialState.find(
      (el: ParamsPerType) => el.propertyType === property?.propertyType
    );
    if (selectedType) setParams(selectedType);
  }, [property?.propertyType]);

  if (isLoading || !property)
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="xl:max-w-[1430px] lg:max-w-[1240px] container  mx-auto px-0 sm:px-0 md:px-4 pb-8">
      {property && (
        <SEO
          title={property?.propertyTitle}
          description={property?.propertyDescription}
          name="deehiy"
          type="preview page"
          image={property?.images[0].url}
        />
      )}
      <div className="w-full h-8 flex items-center bg-deehiy text-white justify-center text-center font-bold">
        Preview
      </div>
      {/* slideshow for Mobile */}
      <div className="block sm:hidden">
        <MobileSlideshow
          isWL={false}
          businessId={property.businessId}
          list={property?.images}
          propertyId={property?.propertyId}
          isSaved={isSaved}
          saveProperty={saveProperty}
        />
      </div>
      {/* title and share buttons */}

      <div className="flex justify-between pt-2 sm:pt-10 items-bottom px-4 sm:px-0">
        <div className="listing-title w-full sm:w-3/5">
          <div className="line-clamp-2 sm:line-clamp-1">{property?.propertyTitle}</div>
        </div>

        <div className="hidden sm:flex items-center justify-end font-bold space-x-6 sm:space-x-12 duration-200 w-2/5">
          <Dropdown
            arrowIcon={false}
            inline={true}
            placement="bottom-end"
            label={
              <div className=" hover:text-deehiy text-defaultText  justify-center">
                {/* <BsFillShareFill className="my-auto w-4 h-4 sm:w-4 sm:h-4" /> */}
                <ItemIcon size={5} Icon={shareSVG} />
              </div>
            }
          >
            <Dropdown.Item>
              <Share type="properties" id={property?.propertyId} />
            </Dropdown.Item>
          </Dropdown>

          <button
            onClick={saveProperty}
            className=" hover:text-deehiy text-defaultText justify-center items-center"
          >
            <>
              {/* <AiOutlineHeart className="my-auto w-4 h-4 sm:w-5 sm:h-5" /> */}
              <ItemIcon size={5} Icon={loveSVG} />
            </>
          </button>

          <div
            onClick={handlePost}
            className=" gap-x-2 flex px-2 py-1 rounded-xl border border-defaultText items-center hover:cursor-pointer md:text-xs lg:text-base "
          >
            {t('Post on Square')}
            <ItemIcon Icon={SquareSVG} size={5} />{' '}
          </div>
        </div>
      </div>
      <div className="px-4 mt-[-14px] flex sm:hidden listing-details-section">
        {property?.city}, {property?.country}
      </div>
      {/* images gallery */}
      <div className="hidden sm:block px-4 sm:px-0">
        <Gallery images={property?.images} />
      </div>
      {/* body */}
      <span className="hidden sm:block text-gray-400 px-4 sm:px-0 text-xs sm:text-sm">
        {t('listing_ID')}: {property?.propertyId}
      </span>
      <div className="flex flex-col w-full sm:flex-row text-sm sm:text-sm px-4 sm:px-0">
        {/* details Desktop */}
        <div className="flex flex-col w-full sm:w-3/4 pt-6 sm:pt-12 gap-x-2 sm:gap-x-8">
          <div className=" flex-col space-y-3 sm:space-y-0 sm:grid grid-flow-col">
            {property && property?.propertyType !== LAND && (
              <>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Bedrooms')}</span>
                  <span className="font-semibold">
                    {' '}
                    {property?.bedrooms === 0
                      ? 'Studio'
                      : property?.bedrooms >= 9
                      ? '9+'
                      : property?.bedrooms}
                  </span>
                </div>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Baths')}</span>
                  <span className="font-semibold">
                    {' '}
                    {property?.bathrooms >= 9 ? '9+' : property?.bathrooms}
                  </span>{' '}
                </div>
                <div className="flex justify-between sm:flex-col sm:space-y-3">
                  <span>{t('Home size')}</span>
                  <span className=" font-semibold">
                    {' '}
                    {property?.homeSize} {t(areaUnit.homeUnit)}
                  </span>
                </div>
              </>
            )}
            {property && property?.propertyType !== CONDO && (
              <div className="flex justify-between sm:flex-col sm:space-y-3">
                <span>{t('Lot size')}</span>
                <span className="font-semibold">
                  {' '}
                  {property?.lotSize} {t(areaUnit.landUnit)}
                </span>
              </div>
            )}
          </div>
          <div className="listing-details-section whitespace-pre-wrap">
            <TranslateTextDropdown text={translatedText} setTranslatedText={setTranslatedText} />
            <ReadMore max={400}>{translatedText}</ReadMore>
          </div>

          <section className="flex-col listing-details-section">
            <span className="listing-title">{t('Property information')}</span>
            <div className="flex py-6 sm:py-8">
              <div className="flex flex-col sm:flex-row w-full gap-x-3 sm:gap-x-24 gap-y-4">
                <div className="flex flex-col w-full sm:w-1/2 space-y-4">
                  <div className="flex justify-between">
                    <span>{t('Country')}</span>
                    <span className="text-md font-semibold flex justify-end">
                      {property?.country}
                    </span>
                  </div>
                  {params?.details.builtIn && property?.builtInDate && (
                    <div className="flex justify-between">
                      <span>{t('Built in')}</span>
                      <span className="font-semibold flex justify-end whitespace-nowrap">
                        {property?.builtInDate}
                      </span>
                    </div>
                  )}
                  {params?.details.floorLevel &&
                    property?.floorLevel != null &&
                    property.floorLevel !== 0 && (
                      <div className="flex justify-between">
                        <span>{t('Floor level')}</span>
                        <span className="font-semibold flex justify-end">
                          {property?.floorLevel}
                        </span>
                      </div>
                    )}
                  {property?.isForRent && (
                    <div className="flex justify-between">
                      <span>{t('Available from')}</span>
                      <span className="font-semibold flex justify-end">
                        {property?.availability
                          ? format(new Date(property.availability), 'dd/MM/yyyy')
                          : t('now')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full sm:w-1/2 justify-end space-y-4">
                  <div className="flex w-full gap-x-6 justify-between">
                    <span>{t('City')}</span>
                    <span className="font-semibold flex justify-end line-clamp-1">
                      {property?.city}
                    </span>
                  </div>
                  {params?.details.lastRenovation && property?.lastRenovationDate && (
                    <div className="flex justify-between">
                      <span className="whitespace-nowrap">{t('Last renovation in')}</span>
                      <span className="font-semibold flex justify-end whitespace-nowrap">
                        {property?.lastRenovationDate}
                      </span>
                    </div>
                  )}
                  {params?.details.ceilingHeight &&
                    property?.ceilingHeight != null &&
                    property.ceilingHeight !== 0 && (
                      <div className="flex justify-between">
                        <span className="whitespace-nowrap">{t('Ceiling height')}</span>
                        <span className="font-semibold flex justify-end">
                          {property.ceilingHeight} m
                        </span>
                      </div>
                    )}

                  {params?.details.parking &&
                    property?.parkingLots != null &&
                    property.parkingLots !== 0 && (
                      <div className="flex justify-between">
                        <span className="whitespace-nowrap">{t('Parking lots')}</span>
                        <span className="font-semibold flex justify-end whitespace-nowrap">
                          {property?.parkingLots}
                        </span>
                      </div>
                    )}
                  {params?.details.furnishingStatus && property?.furnishingStatus && (
                    <div className="flex justify-between">
                      <span>{t('Furnishing status')}</span>
                      <span className="font-semibold flex justify-end">
                        {t(getFurnishingStatusLabel(property?.furnishingStatus))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full gap-8 place-content-center">
              {/* diligence */}
              {/* <div className='flex flex-col w-2/5'>
                <details className='  rounded-xl border border-deehiy p-4  bg-white'>
                  <summary className='fond-semibold text-deehiy py-3 font-semibold pl-6'>
                    property due diligence
                  </summary>
                  <p className='flex flex-col space-y-3 pl-4 pt-3'>
                    <div className='flex space-x-8'>
                      <span>
                        <ImCheckmark className='w-5 h-5 text-green-500' />
                      </span>
                      <span>title deed in order</span>
                    </div>
                    <div className='flex space-x-6'>
                      <span>
                        {" "}
                        <FaQuestion className='w-5 h-5 text-yellow-300' />
                      </span>
                      <span>property taxes paid</span>
                    </div>
                    <div className='flex space-x-6'>
                      <span>
                        <ImCheckmark className='w-5 h-5 text-green-500' />
                      </span>
                      <span>building permit in order</span>
                    </div>
                    <div className='flex space-x-6'>
                      <span>
                        <ImCheckmark className='w-5 h-5 text-green-500' />
                      </span>
                      <span>title deed in order</span>
                    </div>
                    <div className='flex space-x-6'>
                      <span>
                        {" "}
                        <ImCross className='w-5 h-5 text-red-500' />
                      </span>
                      <span>property registration in order</span>
                    </div>
                  </p>
                </details>
              </div> */}

              {/* fix for you */}
              <div
                className="hidden sm:flex flex-col text-black w-full cursor-pointer"
                onClick={() => window.open(promoted.link, '_blank')}
                // onClick={() => navigate('/square')}
              >
                <img alt="" src={promoted.banner} className="w-full" />
              </div>
            </div>
          </section>

          <section className="flex flex-col listing-details-section">
            <div className="listing-title">{t('What this property offers')}</div>

            <ul className=" overflow-auto grid grid-cols-2 max-h-[250px]">
              {property?.features.map((f: any) => {
                return (
                  <li className="flex gap-x-3 items-center text-deehiy">
                    <ItemIcon Icon={getSVGByTitle(f.featureTitle)} size={10} />
                    <span className="text-defaultText">{t(f.featureTitle)}</span>
                  </li>
                );
              })}
            </ul>
          </section>

          {/* location map */}
          <section className="flex flex-col listing-details-section">
            <div className="listing-title">{t('This property is here')}</div>

            {property.showExactLocation && (
              <div className="flex whitespace-pre-wrap gap-x-1 pb-4">
                <span>{t('Address')}:</span>
                <span className="semibold">
                  {`${property?.streetNameHouseNumber}, ${property?.postalCode}, ${property?.city}, ${property?.country}`}
                </span>
              </div>
            )}

            <ListingMap
              center={{ lat: property?.latitude, lng: property?.longitude }}
              isToggled={property.showExactLocation}
            />
          </section>
        </div>
        {/* price and recommended properties */}
        <div className="flex flex-col w-full sm:w-1/3  pl-0 sm:pl-20 pt-0 pb-12 sm:pt-12 sm:pb-6">
          {/* price */}
          <div className="sticky top-20 flex w-full justify-evenly flex-row sm:flex-col space-y-2 border-none sm:border border-deehiy rounded-2xl shadow-none sm:shadow-xl p-4 sm:p-10 mx-auto">
            {property.isForSale || property.isOffPlan ? (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">{t('Sales price')}</span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <>
                    {property.isPOR ? (
                      <div className="text-deehiy font bold">{t('Price on Request')}</div>
                    ) : (
                      <NumericFormat
                        value={property?.listingPrice}
                        thousandSeparator=" "
                        displayType={'text'}
                        prefix={property.isoCodeCurrency + ' '}
                      />
                    )}
                  </>
                </span>
                {property.hasSellerFinance && (
                  <div className="flex gap-x-2 font-normal items-center text-sm">
                    <img src="/images/icons/seller-finance.svg" className="w-5 h-5" />
                    <span>{t('Seller finance available')}</span>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {property.isForRent && (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">
                  {t('Rental price / month')}
                </span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <NumericFormat
                    value={property?.monthlyRentPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={property.isoCodeCurrency + ' '}
                  />
                </span>
              </div>
            )}
            {property.hasDailyRental && (
              <div className="flex flex-col">
                <span className="hidden sm:flex text-sm sm:text-base">
                  {t('Rental price / day')}
                </span>
                <span className="hidden sm:flex text-xl sm:text-2xl font-bold pb-4">
                  <NumericFormat
                    value={property?.dailyRentPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={property.isoCodeCurrency + ' '}
                  />
                </span>
              </div>
            )}
            <div className="flex sm:hidden">
              <Avatar isSales={contact?.isSalesRep} image={contact?.image} />
            </div>

            <hr className="pb-4 opacity-80 inline-block border-deehiy" />
            <div className="flex flex-row sm:flex-col">
              <span className="hidden sm:flex text-sm sm:text-base">{t('Listed by')}</span>
              <div className="flex space-x-6 justify-center items-center">
                <Link to={contact.isBusiness ? `/business/${contact.id}` : `/member/${contact.id}`}>
                  <div className="hidden sm:flex">
                    <Avatar isSales={contact?.isSalesRep} image={contact?.image} size={20} />
                  </div>
                  <span className="font-semibold">
                    <span className="flex items-baseline gap-2">
                      <span className="flex sm:hidden text-sm sm:text-base font-normal">
                        {t('Listed by')}
                      </span>
                      <span className="mx-auto">{contact.name}</span>
                      {contact?.isVerified && (
                        <Tooltip
                          text={t('this user has provided proof of identity')}
                          Component={() => (
                            <img
                              src="/images/icons/verified_profile.svg"
                              className="text-deehiy w-5 h-5"
                            />
                          )}
                        />
                      )}
                    </span>
                    {/* <div className="flex py-2 items-center text-deehiy justify-center">
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <span>5</span>
                      <span className="whitespace-nowrap flex pl-3 text-sm items-baseline font-light text-gray-400">
                        (12 reviews)
                      </span>
                    </div> */}
                  </span>
                </Link>
              </div>
            </div>
            {currentUser?.id !== contactId && (
              // <div className="hidden sm:flex justify-evenly items-center py-4 pt-8 space-x-2">
              //   <button className="primary-btn flex gap-x-2" onClick={addContactHandler}>
              //     <img src="/images/icons/business/add_member_white.svg" className="h-5 w-5" />{' '}
              //     {t('Add')}
              //   </button>

              <Contact chatButtonHandler={chatButtonHandler} contact={contact} />
              // </div>
            )}
          </div>
          {/* recommended properties */}
          <div className="hidden">
            <ListingRecommendedSide />
          </div>
        </div>
      </div>
      <div className="fixed sm:hidden py-3 px-4 w-full flex items-center bg-white z-10 bottom-0 justify-between border border-transparent border-t-deehiy">
        <div className="flex-flex-col">
          {property.isForSale || property.isOffPlan ? (
            <div className="flex gap-x-2 justify-between">
              <span className="flex text-sm sm:text-base">{t('Sales price')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                {property.isPOR ? (
                  <div className="text-deehiy font bold">{t('Price on Request')}</div>
                ) : (
                  <NumericFormat
                    value={property?.listingPrice}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={property.isoCodeCurrency + ' '}
                  />
                )}
              </span>
            </div>
          ) : (
            ''
          )}
          {property.isForRent && (
            <div className="flex gap-x-2 justify-between">
              <span className="flex text-sm sm:text-base">{t('Rental price')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                <NumericFormat
                  value={property?.monthlyRentPrice}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={property.isoCodeCurrency + ' '}
                />
              </span>
            </div>
          )}
          {property.hasDailyRental && (
            <div className="flex text-gray-400 gap-x-2 justify-between">
              <span className="flex text-sm ">{t('Rental price / day')}</span>
              <span className="flex text-sm sm:text-base font-bold">
                <NumericFormat
                  value={property?.dailyRentPrice}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={property.isoCodeCurrency + ' '}
                />
              </span>
            </div>
          )}
        </div>
        {/* <button
          onClick={handleContact}
          className="flex py-1 px-3 sm:text-base font-bold rounded-xl  bg-deehiy text-white border gap-x-2 items-center shadow-sm"
        >
          Get in touch
        </button> */}
        {currentUser?.id !== contactId && (
          <Contact chatButtonHandler={chatButtonHandler} contact={contact} />
        )}
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default PreviewListingPage;
