import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const HuntCard = () => {
  const { currentUser } = useAuth(); // Get currentUser from your useAuth hook
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (currentUser?.id) {
      // User is logged in, navigate to addHunt
      navigate('/users/addhunt');
    } else {
      // User is not logged in, navigate to login and save current location for redirect
      navigate('/login', { state: { redirectTo: location.pathname } });
    }
  };

  return (
    <div
      className="relative market-card bg-deehiy rounded-none flex items-center justify-center"
      key={'a'}
      onClick={handleClick}
    >
      <div className="absolute top-9 sm:top-5 mx-auto text-white text-center font-bold text-2xl sm:text-base px-3">
        Tired of searching for hours ?
      </div>
      <img src="/images/hunt.svg" alt="Hunt Ad" />
    </div>
  );
};

export default HuntCard;
