import React from 'react';
import { BiWorld } from 'react-icons/bi';
import { useGlobal } from '../../contexts/global';
import { useProperty } from '../../contexts/property';
import { initialFilter } from '../../initialStates/initialStates';

const SeeAllListingsBtn = () => {
  const { isWL, keyword } = useGlobal();
  const { setFilter, setTempFilter } = useProperty();

  const handleSeeWorld = () => {
    setFilter(initialFilter);
    setTempFilter(initialFilter);
  };
  return (
    <>
      {!isWL && !keyword && (
        <button
          onClick={handleSeeWorld}
          className="group primary-btn z-30 mb-32 mt-12 font-bold p-6 rounded-full w-56 mx-auto flex space-x-2 items-center justify-center"
        >
          <span>Search the Globe</span>
          <BiWorld className="groupd-hover:animate-spin h-5 w-5" />
        </button>
      )}
    </>
  );
};

export default SeeAllListingsBtn;
