import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { format, parseISO } from 'date-fns'; // Make sure to install date-fns
import { getAllPromotedCardClicks } from '../services/promotedCardService';

type ClickDataEntry = {
  date: string; // Explicitly define `date` as a string
  [cardName: string]: number | string; // Allow dynamic properties (cardName) with value as either string (date) or number (clicks)
};
type GroupedData = {
  [cardName: string]: { date: string; clicks: number }[]; // Each card has an array of clicks
};

const AdminPromotedCardsPage = () => {
  const [data, setData] = useState<ClickDataEntry[]>([]);
  const [totalClicks, setTotalClicks] = useState<{ [cardName: string]: number }>({});
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088fe', '#00c49f'];

  // all cards, displayed by day
  // const processClickData = (groupedData: GroupedData) => {
  //   // Flatten the grouped data into a format suitable for Recharts
  //   const chartData: ClickDataEntry[] = [];
  //   const totals: { [cardName: string]: number } = {};

  //   // Step 1: Get all unique dates
  //   const allDates: string[] = [];
  //   Object.values(groupedData).forEach((clicks) => {
  //     clicks.forEach((click) => {
  //       if (!allDates.includes(click.date)) {
  //         allDates.push(click.date);
  //       }
  //     });
  //   });

  //   // Step 2: Create the chartData by iterating through allDates
  //   allDates.forEach((date) => {
  //     const entry: ClickDataEntry = { date };

  //     Object.keys(groupedData).forEach((cardName) => {
  //       const clickData = groupedData[cardName].find((click: any) => click.date === date);
  //       const clicks = clickData ? clickData.clicks : 0;
  //       entry[cardName] = clicks;

  //       totals[cardName] = (totals[cardName] || 0) + clicks;
  //     });

  //     chartData.push(entry);
  //   });

  //   setTotalClicks(totals);

  //   return chartData;
  // };

  const processClickData = (groupedData: GroupedData) => {
    // Flatten the grouped data into a format suitable for Recharts
    const chartData: ClickDataEntry[] = [];
    const totals: { [cardName: string]: number } = {};

    // Step 1: Get all unique months
    const allMonths: string[] = [];
    Object.values(groupedData).forEach((clicks) => {
      clicks.forEach((click) => {
        const month = format(parseISO(click.date), 'yyyy-MM'); // Format date as YYYY-MM
        if (!allMonths.includes(month)) {
          allMonths.push(month);
        }
      });
    });

    // Step 2: Create the chartData by iterating through allMonths
    allMonths.forEach((month) => {
      const entry: ClickDataEntry = { date: month };

      Object.keys(groupedData).forEach((cardName) => {
        // Sum clicks for the given month
        const monthClicks = groupedData[cardName]
          .filter((click: any) => format(parseISO(click.date), 'yyyy-MM') === month)
          .reduce((sum: number, click: any) => sum + click.clicks, 0);

        entry[cardName] = monthClicks;

        totals[cardName] = (totals[cardName] || 0) + monthClicks;
      });

      chartData.push(entry);
    });

    setTotalClicks(totals);

    return chartData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllPromotedCardClicks();
        const clicks = response.data;
        console.log('response.data', response.data);

        // Keep only the data for "East Coast Real Estate"
        const filteredClicks = {
          'East Coast Real Estate': clicks['East Coast Real Estate'],
        }; // Process the data into a format suitable for the chart
        const formattedData = processClickData(filteredClicks);
        console.log('FORMATTEDDATA', formattedData);

        setData(formattedData);
      } catch (error) {
        console.error('Error fetching promoted card clicks:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="pt-12">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {Object.keys(data[0] || {})
            .filter((key) => key !== 'date')
            .map((cardName, index) => (
              <Line
                key={cardName}
                type="monotone"
                dataKey={cardName}
                name={cardName}
                stroke={colors[index % colors.length]}
                dot={false}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>

      {/* Table for total clicks */}
      <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Promoted Card</th>
            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Total Clicks</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(totalClicks).map(([cardName, clicks]) => (
            <tr key={cardName}>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{cardName}</td>
              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{clicks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPromotedCardsPage;
