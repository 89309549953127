import React from 'react';
import { useTranslation } from 'react-i18next';

const ChatButton = ({ chatButtonHandler }: any) => {
  const { t } = useTranslation();

  return (
    <button
      className="primary-btn  m-0 rounded-full flex gap-x-2 justify-center items-center w-[100px]"
      onClick={chatButtonHandler}
    >
      <img src="/images/icons/chat.svg" className="h-5 w-5" />
      {t('Chat')}
    </button>
  );
};

export default ChatButton;
