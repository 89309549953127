import React, {
  createContext,
  useState,
  useContext,
  // useEffect,
  useReducer,
  useCallback,
} from 'react';
import { useAuth } from './auth';
import { useInternationalization } from './internationalization';
// import { useGlobal } from './global';
import { Property, LatLong, MarketFilter } from '../types/types';
import { initialPropContext, initialFilter, initialProperty } from '../initialStates/initialStates';
import {
  addProperty,
  saveInProgressProperty,
  updateProperty,
  getAllProperties,
  getAllPropertiesForMap,
  getPropertyById,
  getPublicPropertyById,
  getMaxListingPrice,
  getPropertiesByUserId,
} from '../services/propertyService';
import { adminUpdateProperty } from '../services/adminService';
import { propertyReducer } from '../reducers/propertyReducer';
import {
  SET_PROPERTIES,
  SET_USER_PROPERTIES,
  SET_COORDINATES,
  SET_CURRENT_PROPERTY,
  SET_FILTER,
  SET_MAX_LISTINGPRICE,
  SET_PAGE_INDEX,
  SET_COUNT,
} from '../actions/actions';
import { debounce } from '../utils/debounceThrottle';
import { getUserLocalisationByIP } from '../utils/getCountry';
import { getCurrencyByCountryCode } from '../utils/getCurrencyByCountry';

export const PropertyContext = createContext(initialPropContext);

export const PropertyProvider = ({ children }: any) => {
  const { currentUser } = useAuth();
  // const { keyword } = useGlobal();
  const { setCurrency } = useInternationalization();
  const [hasMore, setHasMore] = useState(true);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const [showMap, setShowMap] = useState(false);

  const [
    {
      property,
      properties,
      userProperties,
      currentProperty,
      coordinates,
      filter,
      maxListingPrice,
      pageIndex,
      count,
    },
    dispatch,
  ] = useReducer(propertyReducer, initialPropContext);

  /**
   * Sets the latitude and longitude coordinates for the search filter.
   * @param {LatLong} latLong - An object containing latitude and longitude values.
   *
   * @returns {void}
   */
  const setCoordinates = (latLong: LatLong) => {
    dispatch({ type: SET_COORDINATES, payload: latLong });
  };

  /**
   * Set the current page index to the provided value and update the store
   * @param newIndex - The new page index
   */
  const setPageIndex = (newIndex: number) => {
    dispatch({ type: SET_PAGE_INDEX, payload: newIndex });
  };

  /**
   * Sets the properties in the store.
   * @param {Property[]} editedProperties - The properties to set in the store.
   */
  const setProperties = (editedProperties: Property[]) => {
    dispatch({ type: SET_PROPERTIES, payload: editedProperties });
  };

  /**
   * Sets the filter for the property search page and resets the properties array and pagination index.
   * @param {Filter} params - The filter parameters to set. If not provided, the filter will be reset to its initial state.
   */
  const setFilter = (params?: MarketFilter) => {
    // Set the filter parameters if provided
    if (params) {
      dispatch({ type: SET_FILTER, payload: params });
    } else {
      // Otherwise, reset the filter to its initial state
      dispatch({
        type: SET_FILTER,
        // payload: { ...initialFilter, listingPrice: [0, maxListingPrice] },
        payload: { ...initialFilter },
      });
    }
  };

  /**
   * Updates the current property in the application state with the provided property object.
   * If no property object is provided, it defaults to the initial property object.
   * @param editedProperty - The property object to set as the current property (optional).
   */
  const setCurrentProperty = (editedProperty = initialProperty) => {
    dispatch({ type: SET_CURRENT_PROPERTY, payload: editedProperty });
  };

  /**
   * Fetches the maximum listing price and sets it as a filter, returning the value.
   * @returns The maximum listing price.
   * @throws An error if the request fails.
   */
  const fetchMaxListingPrice = async (curr: string): Promise<number> => {
    try {
      const res = await getMaxListingPrice(curr);
      dispatch({ type: SET_MAX_LISTINGPRICE, payload: res.data });

      const filterWithMaxPrice = {
        ...filter,
        listingPrice: [0, res.data],
      };
      dispatch({ type: SET_FILTER, payload: filterWithMaxPrice });

      return res.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Creates a new property and saves it to the database.
   * @param {Property} editedProperty - The edited property to be saved.
   * @returns {Promise<string>} A promise that resolves to 'success' on successful creation, or an Error message on failure.
   */
  const createProperty = async (editedProperty: Property) => {
    const { imagesTempFiles, floorplansTempFiles, features } = editedProperty;
    let imgArr = [];
    let fpArr = [];
    let featArr: any = [];

    if (typeof imagesTempFiles !== 'undefined' && imagesTempFiles.length > 0) {
      imgArr = imagesTempFiles.map((file: any) => file.url);
    }
    if (typeof floorplansTempFiles !== 'undefined' && floorplansTempFiles.length > 0) {
      fpArr = floorplansTempFiles.map((file: any) => file.url);
    }

    if (typeof features !== 'undefined' && features.length > 0) {
      featArr = features.map((title: any) => {
        return { featureTitle: title };
      });
    }

    const newProperty = {
      ...editedProperty,
      userId: currentUser?.id,
      cloudinaryImages: imgArr,
      cloudinaryFps: fpArr,
      features: featArr,
    };

    try {
      await addProperty(newProperty);

      setCurrentProperty(initialProperty);

      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Saves the given edited property to the database for the current user.
   *
   * @param editedProperty - The edited property to save.
   * @returns A Promise that resolves to a string 'success' if the save was successful.
   *          Otherwise, it resolves to the error message from the server.
   * @throws An Error if an error occurs during the save process.
   */
  const saveProperty = async (editedProperty: Property) => {
    const { imagesTempFiles, floorplansTempFiles } = editedProperty;
    let imgArr = [];
    let fpArr = [];

    if (typeof imagesTempFiles !== 'undefined' && imagesTempFiles.length > 0) {
      imgArr = imagesTempFiles.map((file: any) => file.url);
    }
    if (typeof floorplansTempFiles !== 'undefined' && floorplansTempFiles.length > 0) {
      fpArr = floorplansTempFiles.map((file: any) => file.url);
    }

    const newProperty = {
      ...editedProperty,
      userId: currentUser?.id,
      cloudinaryImages: imgArr,
      cloudinaryFps: fpArr,
    };

    try {
      await saveInProgressProperty(newProperty);
      setCurrentProperty(initialProperty);

      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Modifies a property by sending an API request with updated property data.
   * @param updatedProperty - The updated property data to send in the request.
   * @returns A string 'success' if the API request is successful.
   * @throws An error object with the API response data as the error message if the API request fails.
   */
  const modifyProperty = async (updatedProperty: any) => {
    try {
      await updateProperty(updatedProperty);

      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Modifies an existing property in the system as an admin
   *
   * @param {Object} updatedProperty - The updated property object to be saved
   * @returns {Promise<string>} A Promise that resolves to a string indicating success or rejects with an error message
   * @throws {Error} An error is thrown if an error occurs during the update process
   */
  const adminModifyProperty = async (updatedProperty: any) => {
    try {
      await adminUpdateProperty(updatedProperty);
      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Retrieves properties belonging to a specific user by their ID.
   *
   * @param {number} id - The ID of the user to retrieve properties for.
   * @param {curr} string - The currency to apply.
   * @returns {Promise<Array>} A Promise that resolves to an array of properties belonging to the specified user.
   * @throws {Error} If there is an error with the API request or response.
   */
  const fetchPropertiesByUserId = async (id: number, curr: string) => {
    try {
      const res = await getPropertiesByUserId(id, curr);
      dispatch({
        type: SET_USER_PROPERTIES,
        payload: res.data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Fetches all properties based on the current filter and page index,
   * then updates the properties state and sets the total count in the context
   * state. Returns the total count of properties fetched.
   *
   * @returns {Promise<number | Error>} A promise that resolves to the total count
   * of properties fetched, or rejects with an error.
   */

  const fetchAllProperties = async (
    updatedFilter: MarketFilter,
    page: number,
    updatedProperties: Property[] = []
  ) => {
    let newProperties = [];
    try {
      const response = await getAllProperties(updatedFilter, page);

      if (page == 1) {
        newProperties = [...response.data.properties.rows];
      } else {
        newProperties = [...updatedProperties, ...response.data.properties.rows];
      }
      // Check for duplicated propertyId
      const propertyIds = new Set();
      const dupProperties = newProperties.filter((pr: any) => {
        if (pr.type === 'promotedListing' || pr.type === 'promotedCard') {
          return true;
        }
        if (propertyIds.has(pr.propertyId)) {
          // alert(`duplicate:: ${pr.propertyId}`);
          console.log('duplicate::', pr);
          return false;
        } else {
          propertyIds.add(pr.propertyId);
          return true;
        }
      });
      console.log(
        'current Properties',
        updatedProperties.map((pr: any) => pr.propertyId)
      );
      console.log(
        'incoming properties',
        response.data.properties.rows.map((pr: any) => pr.propertyId)
      );
      console.log('dupProperties', dupProperties[0].propertyId);
      setProperties(newProperties);

      const { totalCount, hasMore: hasMoreListings } = response.data.pagination;

      if (hasMoreListings) {
        setPageIndex(page + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      return { totalCount, hasMoreListings };
    } catch (error: any) {
      console.error('Error fetching properties:', error);
      throw new Error(error.response?.data || 'Unknown error occurred');
    }
  };

  const fetchCount = async (fltr: MarketFilter) => {
    try {
      const response = await getAllProperties(fltr, pageIndex);

      const totalCount = response.data.pagination.totalCount;

      dispatch({ type: SET_COUNT, payload: totalCount });

      return totalCount;
    } catch (error: any) {
      console.error('Error fetching count:', error);
      throw new Error(error.response?.data || 'Unknown error occurred');
    }
  };

  // useCallback back to prevent the creation of a new debounce function (and the reset of the timeout) everytime
  // the provider component is re-rendered due to a filter change
  // const debouncedFetchProperties = useCallback(debounce(fetchAllProperties, 300), [
  //   filter,
  //   pageIndex,
  // ]);
  const debouncedFetchProperties = useCallback(
    debounce(
      (updatedFilter, page, updatedProperties) =>
        fetchAllProperties(updatedFilter, page, updatedProperties),
      300
    ),
    [filter, pageIndex]
  );

  /**
   * Fetches all properties that match the given filter and updates the state with the new data.
   *
   * @returns {Promise<number>} The total number of properties that match the filter.
   * @throws {Error} If an error occurs during the fetch operation.
   */
  const fetchAllPropertiesForMap = async (updatedFilter: MarketFilter) => {
    try {
      const response = await getAllPropertiesForMap(updatedFilter);
      const fetchedProperties = response.data.properties;

      console.log('MAP PROPERTIES', fetchedProperties);

      console.log('response', response);

      setProperties(fetchedProperties);

      return 'success';
    } catch (error: any) {
      console.error('Error fetching properties:', error);
      throw new Error(error.response?.data || 'Unknown error occurred');
    }
  };

  /**
   * Retrieves a property from the API by its ID using the `getPropertyById` function.
   * Returns the property object if successful, otherwise throws an error with the response data.
   *
   * @param id The ID of the property to fetch.
   * @throws Error with response data if the API request fails.
   * @returns The property object retrieved from the API.
   */
  const fetchPropertyById = async (id: number) => {
    try {
      const res = await getPropertyById(id);
      const foundProperty = { ...res.data };

      return foundProperty;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Retrieves a public info of property from the API by its ID using the `getPublicPropertyById` function.
   * Returns the property object if successful, otherwise throws an error with the response data.
   *
   * @param id The ID of the property to fetch.
   * @param curr the selected currency.
   * @throws Error with response data if the API request fails.
   * @returns The property object retrieved from the API.
   */
  const fetchPublicPropertyById = async (id: number, curr: string) => {
    try {
      const res = await getPublicPropertyById(id, curr);

      const foundProperty = { ...res.data };

      return foundProperty;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  /**
   * Initializes the current property state by setting it to the initial property value.
   * This function is typically used when creating a new property or resetting the current property state.
   */
  const initCurrentProperty = () => {
    dispatch({ type: SET_CURRENT_PROPERTY, payload: initialProperty });
  };

  const initCountryFromIP = async () => {
    const storedCoordinates = sessionStorage.getItem('coordinates');
    const storedCountry = sessionStorage.getItem('country');
    let currency = sessionStorage.getItem('currency');

    if (storedCoordinates && storedCountry) {
      console.log('-> getting country from session');
      // Parse and use stored data
      const { lat, lng } = JSON.parse(storedCoordinates);
      setCoordinates({ lat: Number(lat), lng: Number(lng) });
      setTempFilter({
        ...tempFilter,
        country: storedCountry,
      });
      setFilter({
        ...filter,
        country: storedCountry,
      });
    } else {
      console.log('-> getting country from external API');
      const localisation = await getUserLocalisationByIP(); // call extrem IP API
      // Store location data in sessionStorage
      if (localisation && localisation.lat && localisation.lon && localisation.countryCode) {
        sessionStorage.setItem(
          'coordinates',
          JSON.stringify({ lat: localisation.lat, lng: localisation.lon })
        );
        sessionStorage.setItem('country', localisation.countryCode);
      }
      setCoordinates({ lat: Number(localisation.lat), lng: Number(localisation.lon) });
      setTempFilter({
        ...tempFilter,
        country: localisation.countryCode,
      });
      setFilter({
        ...filter,
        country: localisation.countryCode,
      });

      currency = getCurrencyByCountryCode(localisation.countryCode);
    }
    setCurrency(currency || 'THB');
  };

  const value = {
    property,
    currentProperty,
    properties,
    userProperties,
    setProperties,
    setCurrentProperty,
    initCurrentProperty,
    createProperty,
    modifyProperty,
    adminModifyProperty,
    saveProperty,
    debouncedFetchProperties,
    fetchAllProperties,
    fetchAllPropertiesForMap,
    fetchPropertiesByUserId,
    fetchPropertyById,
    fetchPublicPropertyById,
    coordinates,
    setCoordinates,
    filter,
    setFilter,
    tempFilter,
    setTempFilter,
    maxListingPrice,
    fetchMaxListingPrice,
    pageIndex,
    setPageIndex,
    count,
    fetchCount,
    hasMore,
    setHasMore,
    showMap,
    setShowMap,
    initCountryFromIP,
  };

  // change currency or keyword
  // useEffect(() => {
  //   setFilter({ ...filter, currency, keyword });
  // }, [currency, keyword]);

  // useEffect(() => {
  //   initCountryFromIP();
  // }, []);

  return <PropertyContext.Provider value={value}>{children}</PropertyContext.Provider>;
};

export function useProperty() {
  const context = useContext(PropertyContext);

  return context;
}
