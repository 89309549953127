import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { useAuth } from '../../../../contexts/auth';
// import { useNavigate } from 'react-router-dom';
import { Hunt } from '../../../../types/types';
import { adjustImageSize } from '../../../../utils/adjustImageSize';
import {
  updateSubmitMatchService,
  getMatchesByUserIdService,
} from '../../../../services/huntService';
import Loader from '../../../main/Loader';
import { useTranslation } from 'react-i18next';
import Contact from '../../../main/Contact';

const columnHelper = createColumnHelper<Hunt>();

const LeadTable = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<any>(() => []);

  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const appToken = localStorage.getItem('@App:token');
  // const navigate = useNavigate();

  const handleChatAndSubmit = async (info: any) => {
    console.log(info);
    const firstName = info?.row?.original?.hunt.user?.firstName;
    const id = info?.row?.original?.hunt.userId;
    const matchId = info?.row?.original?.id;
    console.log(info);

    await updateSubmitMatchService(matchId);

    window.open(
      `${
        process.env.REACT_APP_MESSENGER_URL
      }?token=${appToken}&id=${id}&username=${firstName}&userSpecificChat=${true}`,
      '_blank'
    );
  };

  const columns = useMemo<ColumnDef<Hunt, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor((row) => row.property.images, {
        id: 'image',
        header: () => <span className="text-xs sm:text-md ">{t('Listings')}</span>,
        cell: (info) => (
          <img
            src={adjustImageSize(info.renderValue()[0].url, 300)}
            alt=""
            className="my-2 sm:my-3 pr-0 max-w-[50px] sm:max-w-[150px] aspect-video"
          />
        ),
      }),
      columnHelper.accessor((row) => row.property.propertyTitle, {
        id: 'propertyTitle',
        header: t('Title'),
        cell: (info) => (
          <div
            title="open listing page"
            className="text-xs sm:text-md items-center justify-start  font-bold flex"
          >
            <div
              onClick={() => window.open(`/properties/${info.row.original.propertyId}`, '_blank')}
              className=" cursor-pointer line-clamp-2 hover:underline"
            >
              {info.getValue()}
            </div>
            {!info.row.original.isSubmitted && (
              <span className="text-deehiy font-bold px-2 py-1 bg-gray-100 rounded-full mx-2">
                new
              </span>
            )}
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('location', {
        id: 'location',
        header: () => <span>{t('Location')}</span>,
        cell: (info) => {
          let formatCity = info.row.original.property.city;
          formatCity = formatCity.charAt(0).toUpperCase() + formatCity.slice(1);
          return (
            <>
              {formatCity}, {info.row.original.property.isoCodeCountry}
            </>
          );
        },
      }),
      columnHelper.accessor('percent', {
        id: 'percent',
        header: () => (
          <span className="text-center text-xs sm:text-md whitespace-nowrap">
            {t('Match Rate')}
          </span>
        ),

        cell: (info) => {
          return (
            <div className="justify-center flex w-full text-xs sm:text-md">
              {info.renderValue()}%
            </div>
          );
        },
      }),
      columnHelper.accessor('huntId', {
        header: '',
        cell: (info) => {
          const user = info?.row?.original?.user;

          return <Contact chatButtonHandler={() => handleChatAndSubmit(info)} contact={user} />;
        },
        enableSorting: false,
      }),
      // columnHelper.accessor('id', {
      //   header: () => <span className="w-full text-center text-xs sm:text-md">{t('Status')}</span>,
      //   cell: (info) =>
      //     info.row.original.isSubmitted ? (
      //       <div className="flex text-deehiy w-full justify-center font-bold text-center  text-xs sm:text-md">
      //         {t('Submitted')}
      //       </div>
      //     ) : (
      //       <div className="flex w-full justify-center">
      //         <button
      //           title="submit this lead"
      //           onClick={() => handleSubmit(info.getValue())}
      //           type="button"
      //           className="px-2 py-1 sm:py-2 sm:px-6 mx-2 sm:mx-4 text-xs sm:text-md rounded-full font-bold duration-300 hover:shadow-md cursor-pointer border border-deehiy  bg-white text-deehiy shadow-sm"
      //         >
      //           {t('Submit')}
      //         </button>
      //       </div>
      //     ),
      // }),
    ];

    const mobileColumns = baseColumns.filter((col: any) => col.id !== 'location') as ColumnDef<
      Hunt,
      any
    >[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setIsLoading(true);

    getMatchesByUserIdService(currentUser?.id)
      .then((res) => {
        console.log(res.data);

        setData(() => [...res.data]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentUser]);

  if (data.length === 0) return <>no leads found</>;
  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="px-3 pt-3 ring-1 rounded bg-white ring-deehiy">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="pb-3" key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                          // }[header.column.getIsSorted() as string] ?? null}
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const isSubmitted = row.original.isSubmitted;
              return (
                <tr
                  className={`${
                    isSubmitted ? 'bg-white' : 'bg-green-100'
                  } last:border-b-transparent border border-x-transparent  border-y-deehiy`}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeadTable;
