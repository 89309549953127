import React, { useState, useEffect } from 'react';
import UserDropdown from './UserDropdown';
import { useAuth } from '../../../contexts/auth';
import { useBusiness } from '../../../contexts/business';
import { Navbar, Dropdown } from 'flowbite-react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as MarketSVG } from '../../assets/header-icons/market.svg';
import { ReactComponent as SquareSVG } from '../../assets/header-icons/square.svg';
import { ReactComponent as CommunitySVG } from '../../assets/header-icons/community.svg';
// import { ReactComponent as globeSVG } from '../../assets/header-icons/globe.svg';
// import { useGlobal } from '../../../contexts/global';
import { POST_TYPES } from '../../../constants/constant';
import Modal2 from '../../listing/Modal2';
import CreatePostModalContent from '../../square/CreatePostModalContent';
import { PostType } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import i18next from './../../../i18n';
// import ControlledPopup from '../ControlledPopup';
import TutoPopup from '../TutoPopup';

export const MainIconMenu = [
  { title: i18next.t('Market'), url: '/', Icon: MarketSVG, isSelected: false },
  {
    title: 'Square',
    url: '/square',
    Icon: SquareSVG,
    isSelected: false,
  },
  {
    title: 'Community',
    url: '/community',
    Icon: CommunitySVG,
    isSelected: false,
  },
  // {
  //   title: 'All Deehiy',
  //   url: '/all',
  //   Icon: globeSVG,
  //   isSelected: false,
  // },
];

export const DropdownItem = ({ itemLabel, onClick, imageUrl }: any) => {
  const isMobile = window.innerWidth <= 768;

  if (isMobile)
    return (
      <div className="flex gap-x-3 text-sm items-center" onClick={onClick}>
        {imageUrl ? (
          <img src={imageUrl} alt="ico" className="w-9 h-9 sm:w-8 sm:h-8" />
        ) : (
          <div className="w-9 h-9 sm:w-8 sm:h-8 flex justify-center items-center">-</div>
        )}
        {itemLabel}
      </div>
    );
  return (
    <Dropdown.Item className=" flex gap-x-3 text-sm items-center" onClick={onClick}>
      {imageUrl && <img src={imageUrl} alt="ico" className="w-9 h-9  sm:w-8 sm:h-8" />}
      {itemLabel}
    </Dropdown.Item>
  );
};

export const CustomDropwdown = ({ children, Btn }: any) => {
  const isMobile = window.innerWidth <= 768;

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex ">
      {isMobile ? (
        <>
          <div
            onClick={() => {
              setOpenModal(!openModal);
            }}
          >
            {Btn}
          </div>
          <Modal2 showModal={openModal} setShowModal={setOpenModal}>
            {children}
          </Modal2>
        </>
      ) : (
        <Dropdown
          className="z-40 w-60 shadow-md"
          arrowIcon={false}
          placement="bottom"
          inline={true}
          label={Btn}
        >
          {children}
        </Dropdown>
      )}
    </div>
  );
};

export const Navigation = ({ label, Search, links, isBusiness }: any) => {
  const { logoutUser, currentUser } = useAuth();
  const { currentBusiness } = useBusiness();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('general');
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const appToken = localStorage.getItem('@App:token');

  const initialBtnMenuClass = 'block hamburger md:hidden focus:outline-none';
  const initialMenuClass = `absolute flex-col mt-2 py-8 pl-8 space-y-5 
   font-light text-md text-white bg-deehiy w-full shadow-md right-0`;
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [menuBtnClass, setMenuBtnClass] = useState(initialBtnMenuClass);
  const [menuClass, setMenuClass] = useState(initialMenuClass.concat(' ', 'open'));

  const handleToggle = () => {
    setIsToggleOn(!isToggleOn);
    setMenuBtnClass(isToggleOn ? initialBtnMenuClass : initialBtnMenuClass.concat(' ', 'open'));
    setMenuClass(
      isToggleOn ? initialMenuClass.concat(' ', 'hidden') : initialMenuClass.concat(' ', 'flex')
    );
  };

  const HandleSquareModal = (type: string) => {
    if (!currentUser) navigate('/login', { state: { redirectTo: location } });
    else {
      setModalType(type);
      setShowModal(true);
    }
  };

  // TODO: find a fix. menu always open on mobile when visiting a page
  useEffect(() => {
    setMenuClass(initialMenuClass.concat(' ', 'hidden'));
  }, []);

  const CommonItems = () => {
    return (
      <>
        <DropdownItem
          itemLabel={t('Help center')}
          onClick={() => navigate('/help')}
          imageUrl="/images/icons/icon-dashboard-landing/help.svg"
        />
        <Dropdown.Divider />
        {/* ADMIN */}
        {currentUser?.roles === 'admin' && (
          <DropdownItem
            itemLabel={<div className="text-purple-500 px-6">Admin</div>}
            onClick={() => navigate('/admin/dashboard')}
          />
        )}
        <Dropdown.Divider />
        <DropdownItem
          itemLabel={t('Logout')}
          onClick={logoutUser}
          imageUrl="/images/icons/icon-dashboard-landing/logout.svg"
        />
      </>
    );
  };

  // const MoreUserTools = () => {
  //   return (
  //     <Sidebar>
  //       <Sidebar.Collapse className="text-sm text-deehiy" label="More user tools">
  //         <DropdownItem
  //           itemLabel="Forms"
  //           onClick={() => navigate('/forms')}
  //           imageUrl="/images/icons/icon-dashboard-landing/forms.svg"
  //         />
  //         <DropdownItem
  //           itemLabel="Analytics"
  //           onClick={() => navigate('/analytics')}
  //           imageUrl="/images/icons/icon-dashboard-landing/analytics.svg"
  //         />
  //         <DropdownItem
  //           itemLabel="Wishlist"
  //           onClick={() => navigate('/wishlist')}
  //           imageUrl="/images/icons/icon-dashboard-landing/wishlist.svg"
  //         />
  //       </Sidebar.Collapse>
  //     </Sidebar>
  //   );
  // };

  const DashboardItems = () => {
    return (
      <>
        {/* <DropdownItem
          itemLabel="Profile"
          onClick={() => navigate('/users/profile')}
          imageUrl="/images/icons/icon-dashboard-landing/personal-info.svg"
        /> */}
        <DropdownItem
          itemLabel={t('Property listings')}
          onClick={() => navigate('/users/managelistings')}
          imageUrl="/images/icons/icon-dashboard-landing/listings.svg"
        />
        {/* <DropdownItem
          itemLabel="Community"
          onClick={() => navigate('/network')}
          imageUrl="/images/icons/icon-dashboard-landing/network.svg"
        />
        <DropdownItem
          itemLabel="Messenger"
          onClick={() =>
            window.open(`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`, '_blank')
          }
          imageUrl="/images/icons/icon-dashboard-landing/messaging.svg"
        /> */}
        <DropdownItem
          itemLabel={t('Hunting board')}
          onClick={() => navigate('/users/huntingboard')}
          imageUrl="/images/icons/icon-dashboard-landing/hunting-board.svg"
        />
      </>
    );
  };

  const BusinessItems = () => {
    return (
      <>
        {/* <DropdownItem
          itemLabel="Business info"
          onClick={() => navigate('/business/profile')}
          imageUrl="/images/icons/icon-dashboard-landing/personal-info.svg"
        /> */}
        <DropdownItem
          itemLabel={t('Property listings')}
          onClick={() => navigate('/business/managelistings')}
          imageUrl="/images/icons/icon-dashboard-landing/listings.svg"
        />
        {/* <DropdownItem
          itemLabel="Community"
          onClick={() => navigate('/network')}
          imageUrl="/images/icons/icon-dashboard-landing/network.svg"
        /> */}
        {/* <DropdownItem
          itemLabel="Messenger"
          onClick={() => navigate('/messenger')}
          imageUrl="/images/icons/icon-dashboard-landing/messaging.svg"
        /> */}
        {/* <DropdownItem
          itemLabel="Hunting board"
          onClick={() => navigate('/huntingboard')}
          imageUrl="/images/icons/icon-dashboard-landing/hunting-board.svg"
        /> */}
        <DropdownItem
          itemLabel="Account settings"
          onClick={() => navigate('/business/settings')}
          imageUrl="/images/icons/icon-dashboard-landing/settings.svg"
        />
        {/* <MoreUserTools /> */}
      </>
    );
  };

  const SwitchItem = ({ url, imageUrl, SwitchItemLabel }: any) => {
    return (
      <div onClick={() => navigate(url)} className="items-center gap-x-3 flex cursor-pointer ">
        <img className="w-5 h-5 rounded-full" src={imageUrl} />
        <span className="line-clamp-1">{SwitchItemLabel}</span>
      </div>
    );
  };

  const PlusRoundBtn = () => {
    return (
      <Dropdown
        className="z-20 shadow-md"
        arrowIcon={false}
        placement="bottom"
        inline={true}
        label={
          <img
            title={t('Create listings, posts and hunts')}
            src="/images/icons/deehiy-plus.svg"
            className="w-9 h-9"
          />
        }
      >
        {/* <div className="font-bold px-4 pb-2">Just Post It</div> */}
        <DropdownItem
          itemLabel={t('List property')}
          onClick={() => navigate('/addproperty')}
          imageUrl="/images/icons/list-property-round.svg"
        />
        <DropdownItem
          itemLabel={t('Hunt property')}
          onClick={() => navigate('/users/addhunt')}
          imageUrl="/images/icons/hunt-property.svg"
        />
        <Dropdown.Divider />
        <DropdownItem
          itemLabel={t('New post')}
          onClick={() => HandleSquareModal(POST_TYPES.GENERAL)}
          imageUrl="/images/icons/new-post.svg"
        />
        <DropdownItem
          itemLabel={t('Start discussion')}
          onClick={() => HandleSquareModal(POST_TYPES.DISCUSSION)}
          imageUrl="/images/icons/new-discussion.svg"
        />
        <DropdownItem
          itemLabel={t('Write article')}
          onClick={() => HandleSquareModal(POST_TYPES.ARTICLE)}
          imageUrl="/images/icons/new-article.svg"
        />
      </Dropdown>
    );
  };

  const MessengerBtn = () => {
    return (
      <div
        title={t('Open Pulse')}
        className="cursor-pointer"
        onClick={() => {
          if (!currentUser?.id) navigate('/login', { state: { redirectTo: location } });
          else {
            window.open(`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`, '_blank');
          }
        }}
      >
        <img src="/images/icons/deehiy-messenger-logo-headerbar.svg" className="w-9 h-9" />
      </div>
    );
  };

  return (
    <nav className="w-full bg-white shadow-md fixed top-0 z-20 h-14 sm:h-14 flex items-center">
      <div className="mx-auto z-20 px-6 sm:px-20 w-full h-full">
        <div className="flex items-center justify-between h-full">
          <div className="w-1/3 flex">
            <Navbar.Brand className="h-[36px] min-w-[120px]">
              <img
                src="/images/logo.jpg"
                className="cursor-pointer mr-1 h-[36px] w-[120px] "
                alt="Logo"
                style={{ width: '120px', height: '36px' }}
                // onClick={() => navigate('/')}
                onClick={() => {
                  navigate('/');
                  window.location.reload();
                }}
              />
              <span className=" text-defaultText font-bold text-sm hidden xl:flex pt-2 ">
                {t(label)}
              </span>
            </Navbar.Brand>
            {Search && (
              <div className="order-second hidden md:flex justify-left">
                <Search />
              </div>
            )}
          </div>
          {/* <div className="rounded border border-red-500 bg-opacity-80 bg-red-200 p-3">
              BUSINESS ID: {currentBusiness && currentBusiness.businessId} <br />
              BUSINESS ADMIN ID: {currentBusiness && currentBusiness.adminId} <br />
              ROLE ID: {currentUser && currentUser.roleId} <br />
              BUSINESS PROPERTIES: {currentBusiness.properties && currentBusiness.properties.length}
            </div> */}
          <div className=" w-1/3 flex justify-evenly h-full">
            {MainIconMenu.map(({ url, Icon, title }, i) =>
              url ? (
                <NavLink
                  className={({ isActive }) => {
                    return ` ${
                      isActive
                        ? ' border-transparent  border-deehiy text-deehiy'
                        : ' text-defaultText border-white'
                    } h-full w-16 justify-center items-center flex border-b-4 pt-1`;
                  }}
                  to={url}
                  key={i}
                >
                  <div title={t(title)} className="w-7 h-7 items-center flex">
                    <Icon />
                  </div>
                </NavLink>
              ) : null
            )}
          </div>

          <div className="md:flex hidden w-1/3 ">
            <div className="flex justify-end gap-x-4 w-full items-center">
              <div className="hidden w-full justify-evenly items-center">
                {links &&
                  links.map((link: any, i: number) => (
                    <Link
                      key={i}
                      to={link.to}
                      className="text-sm  whitespace-nowrap font-bold my-auto px-4 text-deehiy"
                    >
                      {link.label}
                    </Link>
                  ))}
              </div>
              {/* <ControlledPopup
                Trigger={PlusRoundBtn}
                text={t('popup-tuto-text-1')}
                title={t('popup-tuto-title-1')}
                isOpen={true}
              /> */}
              <TutoPopup
                storageKey="tuto-plus"
                text={t('popup-tuto-text-1')}
                title={t('popup-tuto-title-1')}
                placement="top"
              >
                <PlusRoundBtn />
              </TutoPopup>

              {/* <PlusRoundBtn /> */}
              <MessengerBtn />

              {currentUser ? (
                <CustomDropwdown Btn={<UserDropdown currentUser={currentUser} />}>
                  <div className=" p-4 rounded-sm mb-1">
                    <div className=" rounded-md border border-gray-100 flex font-bold p-2 shadow-md ">
                      {currentBusiness.businessId ? (
                        <>
                          {isBusiness ? (
                            <div className="flex flex-col space-y-6">
                              <SwitchItem
                                url={'/business/dashboard'}
                                imageUrl={currentBusiness.image}
                                SwitchItemLabel={currentBusiness.businessName}
                              />
                              <div
                                onClick={() => navigate('/users/dashboard')}
                                className="text-sm px-1 font-bold cursor-pointer flex space-x-4 text-deehiy"
                              >
                                <img
                                  src="/images/icons/business/profile-green.svg"
                                  alt=""
                                  className="w-5 h-5"
                                />
                                <span>{t('Switch to Personal')}</span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col space-y-6">
                              <SwitchItem
                                url={'/users/dashboard'}
                                imageUrl={currentUser.image}
                                SwitchItemLabel={`${
                                  currentUser?.firstName
                                    ? currentUser?.firstName
                                    : t('Personal Profile')
                                } `}
                              />

                              <div
                                onClick={() => navigate('/business/dashboard')}
                                className="text-sm px-1 font-bold cursor-pointer flex space-x-4 text-deehiy items-center"
                              >
                                <img
                                  src="/images/icons/icon-dashboard-landing/business-profile-green.svg"
                                  alt=""
                                  className="w-6 h-6"
                                />
                                <span>{t('Switch to business')}</span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="flex flex-col space-y-6">
                          <SwitchItem
                            url={'/users/dashboard'}
                            imageUrl={currentUser.image}
                            SwitchItemLabel={`${
                              currentUser?.firstName
                                ? currentUser?.firstName
                                : t('Personal Profile')
                            } `}
                          />
                          <div
                            onClick={() => navigate('/users/addbusiness')}
                            className="text-sm px-1 font-bold cursor-pointer flex space-x-4 text-deehiy"
                          >
                            <img
                              src="/images/icons/icon-dashboard-landing/business-profile-green.svg"
                              alt=""
                              className="w-6 h-6"
                            />
                            <span>{t('Create your business')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Dropdown.Divider />
                  {isBusiness ? <BusinessItems /> : <DashboardItems />}
                  <CommonItems />
                </CustomDropwdown>
              ) : (
                <CustomDropwdown Btn={<UserDropdown currentUser={currentUser} />}>
                  <DropdownItem
                    itemLabel={t('Log in')}
                    onClick={() => navigate('/login', { state: { redirectTo: location } })}
                  />
                  <DropdownItem itemLabel={t('Sign up')} onClick={() => navigate('/register')} />
                </CustomDropwdown>
              )}
            </div>
          </div>
          <button className={menuBtnClass} onClick={handleToggle}>
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        </div>
        <div className="md:hidden">
          <div className={menuClass}>
            <button onClick={handleToggle}>
              <Link to="/">{t('Home')}</Link>
            </button>
            <button onClick={handleToggle}>
              <Link to="/addproperty">{t('List property')}</Link>
            </button>
            <button onClick={handleToggle}>
              <Link to="/users/addhunt">{t('Hunt property')}</Link>
            </button>
            {currentUser ? (
              <>
                <button onClick={handleToggle}>
                  <Link to="/users/dashboard">{t('profile')} </Link>
                </button>
                <button onClick={handleToggle}>
                  <Link to="/users/managelistings">{t('Property listings')}</Link>
                </button>
                <button onClick={logoutUser}>
                  <Link to="/">{t('Logout')}</Link>
                </button>
              </>
            ) : (
              <>
                <button onClick={handleToggle}>
                  <Link to="/login" state={{ redirectTo: location }}>
                    {t('Login')}
                  </Link>
                </button>

                <button onClick={handleToggle}>
                  <Link to="/register">{t('Sign up')}</Link>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <Modal2 showModal={showModal} setShowModal={setShowModal}>
          <CreatePostModalContent type={modalType as PostType} setShowModal={setShowModal} />
        </Modal2>
      )}
    </nav>
  );
};
